import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Helmet } from "react-helmet"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import { useGlobalState } from "../../../global/global"
import { getLanguageFile } from "../../../global/langauge.helper"
import ARTS_IMAGE_1 from "../../../../assets/images/arts/ARTS_IMAGE_1.jpg"
import ARTS_IMAGE_2 from "../../../../assets/images/arts/ARTS_IMAGE_2.jpg"
import ARTS_IMAGE_3 from "../../../../assets/images/arts/ARTS_IMAGE_3.jpg"
import ARTS_IMAGE_4 from "../../../../assets/images/arts/ARTS_IMAGE_4.jpg"
import ARTS_KAPAK from "../../../../assets/images/arts/ARTS_KAPAK.jpg"
import ARTS_KAPAK_2 from "../../../../assets/images/arts/ARTS_KAPAK_2.jpg"

import ARTS_100 from "../../../../assets/images/arts/ARTS_100.png"
import ARTS_120 from "../../../../assets/images/arts/ARTS_120.png"
import ARTS_150 from "../../../../assets/images/arts/ARTS_150.png"

import "./styles.scss"

const ARTSPage = () => {
  const [state] = useGlobalState()

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ARTS®</title>
        </Helmet>
      </div>
      <Header />
      {/* <Container className="introduction-container ">
        <Row>
          <Col className="introduction-text-col col-lg-4 order-12 order-lg-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 className="introduction-text-heading">ETECube</h3>
              <h6 className="copyright-text">®</h6>
            </div>
            <h5 className="introduction-text">
              {getLanguageFile(state.language).Nginr.nginrMainDescription}
            </h5>
          </Col>
        </Row>
      </Container> */}
      <Container className="features-container ">
        <p className="features-text">
          <h4>
            <b>
              <center>
                {getLanguageFile(state.language).Arts.artsMainTitle}
              </center>
            </b>
          </h4>
          <br />
          <p>
            {getLanguageFile(state.language).Arts.artsMainDescription}
            <br />
          </p>

          <Row>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={ARTS_IMAGE_1} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={ARTS_KAPAK} className="features_img"></Image>
            </Col>
          </Row>
          <h4 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleOne}</b>
          </h4>
          <h5 style={{ color: "black", marginLeft: 15 }}>
            <b>
              {getLanguageFile(state.language).Arts.artsSubTitleOneListOneTitle}
            </b>
          </h5>
          <p>
            <ul>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListOneItemOne
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListOneItemTwo
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListOneItemThree
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListOneItemFour
                }{" "}
              </li>
            </ul>
          </p>
          <h5 style={{ color: "black", marginLeft: 15 }}>
            <b>
              {getLanguageFile(state.language).Arts.artsSubTitleOneListTwoTitle}
            </b>
          </h5>
          <p>
            <ul>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemOne
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemTwo
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemThree
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemFour
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemFive
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleOneListTwoItemSix
                }{" "}
              </li>
            </ul>
          </p>

          <Row>
            <Col className="features-img-col">
              <Image src={ARTS_IMAGE_2} className="features_img_center"></Image>
            </Col>
          </Row>

          <h4 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleTwo}</b>
          </h4>
          <h6 style={{ color: "black", marginLeft: 15 }}>
            <b>
              {getLanguageFile(state.language).Arts.artsSubTitleOfSubTitleTwo}
            </b>
          </h6>
          <p>
            <ul>
              <li>
                {getLanguageFile(state.language).Arts.artsSubTitleTwoContentOne}
              </li>
              <li>
                {getLanguageFile(state.language).Arts.artsSubTitleTwoContentTwo}
              </li>
            </ul>
          </p>

          <Row>
            <Col className="features-img-col">
              <Image src={ARTS_KAPAK_2} className="features_img_center"></Image>
            </Col>
          </Row>

          <h5 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleThree}</b>
          </h5>
          <p>
            <b>
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleThreeBoldTextOne
              }
              :
            </b>{" "}
            {getLanguageFile(state.language).Arts.artsSubTitleThreeContentOne}
            <p>
              <b>
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleThreeBoldTextTwo
                }
                :
              </b>{" "}
              {getLanguageFile(state.language).Arts.artsSubTitleThreeContentTwo}
            </p>
            <p>
              <b>
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleThreeBoldTextThree
                }
                :
              </b>{" "}
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleThreeContentThree
              }
            </p>
            <p>
              <b>
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleThreeBoldTextFour
                }
                :
              </b>{" "}
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleThreeContentFour
              }
            </p>
            <p>
              <b>
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleThreeBoldTextFive
                }
                :
              </b>{" "}
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleThreeContentFive
              }
            </p>
          </p>

          <Row>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={ARTS_IMAGE_3} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={ARTS_IMAGE_4} className="features_img"></Image>
            </Col>
          </Row>
          <h5 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleFour}</b>
          </h5>
          <p>
            {getLanguageFile(state.language).Arts.artsSubTitleFourContentOne}
          </p>
          <p>
            {getLanguageFile(state.language).Arts.artsSubTitleFourContentTwo}
          </p>
          <h5 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleFive}</b>
          </h5>
          <h6 style={{ color: "black", marginLeft: 15 }}>
            <b>
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleFiveListOneTitle
              }
            </b>
          </h6>
          <p>
            <ul style={{ listStyleType: "decimal" }}>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemOne
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemTwo
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemThree
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemFour
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemFive
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListOneItemSix
                }{" "}
              </li>
            </ul>
          </p>

          <h6 style={{ color: "black", marginLeft: 15 }}>
            <b>
              {
                getLanguageFile(state.language).Arts
                  .artsSubTitleFiveListTwoTitle
              }
            </b>
          </h6>
          <p>
            <ul>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemOne
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemTwo
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemThree
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemFour
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemFive
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemSix
                }{" "}
              </li>
              <li>
                {" "}
                {
                  getLanguageFile(state.language).Arts
                    .artsSubTitleFiveListTwoItemSeven
                }{" "}
              </li>
            </ul>
          </p>

          <h5
            style={{
              color: "black",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <b>{getLanguageFile(state.language).Arts.artsModelsText}</b>
          </h5>
          <br></br>
          <Row>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image src={ARTS_100} className="features_img_center"></Image>
            </Col>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image src={ARTS_120} className="features_img_center"></Image>
            </Col>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image src={ARTS_150} className="features_img_center"></Image>
            </Col>
          </Row>

          <h4 style={{ color: "black" }}>
            <b>{getLanguageFile(state.language).Arts.artsSubTitleSix}</b>
          </h4>
          <p>{getLanguageFile(state.language).Arts.artsSubTitleSixContent}</p>
          <Row>
            <Col className="features-img-col ">
              <Image src={ARTS_IMAGE_4} className="features_img_center"></Image>
            </Col>
          </Row>
        </p>
      </Container>
      <Footer />
    </Container>
  )
}

export default ARTSPage
